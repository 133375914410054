.service-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;

  .inner-container {
    background: var(--bg-light);
    width: 330px;
    width: 30%;
    height: 360px;
    display: flex;
    flex-direction: column;
    border: 2px solid var(--border);

    border-radius: 12px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }

    .left-container {
      height: 60%;
      width: 100%;
      .founder-image-container {
        height: 95%;
        width: 95%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        .founder-image {
          height: 75%;
          width: 75%;
          object-fit: contain;
        }
      }
    }

    .right-container {
      height: 40%;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 15px;

      .primary-details {
        .name {
          font-size: 1.2rem;
          font-weight: 600;
          color: var(--text-primary);
          margin: 0;
        }

        .designation {
          font-size: 1rem;
          font-weight: 500;
          color: var(--text-dark-grey);
        }
      }

      .about-content {
        margin-top: 5px;
        display: -webkit-box;
        -webkit-line-clamp: 4; /* Clamp to 6 lines across all paragraphs */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;

        .about-paragraph {
          margin: 0;
          font-size: 0.9rem;
          color: var(--text-grey);
          line-height: 1.2rem;
        }
      }

      .read-more-container {
        margin-top: 5px;
        display: flex;
        align-self: flex-end;
        cursor: pointer;
       

        .read-more-text {
          font-size: 0.85rem;
          color: var(--dark-accent);
          line-height: 1.2rem;
          font-weight: 500;
          
        }
      }
    }
  }

  // .inner-container:nth-child(even) {
  //   flex-direction: row-reverse;
  // }
}

.servicemain {
  display: flex;
  height: 90%;
  width: 75%;
  justify-content: center;
  margin: auto;
  margin-top: 1rem;
  flex-wrap: wrap;
  gap: 1.2rem;



  @media (max-width: 900px) {
    width: 90%;
  }

  .box1 {
    background: var(--bg-light);
    width: 330px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border: 2px solid var(--border);
    border-radius: 12px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }

    @media (max-width: 900px) {
      width: 100%;
    }

    .imgdiv {
      width: 100%;
      height: 180px;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }

    .content-div {
      padding: 10px;

      .h4 {
        font-size: 1.2rem;
        font-weight: 600;
        color: var(--text-primary);
        margin: 0;
      }
    

      p {
        margin-top: 5px;
        font-size: 0.9rem;
        color: var(--text-grey);
        line-height: 1.2rem;
      }
    }
  }
}

@media (max-width: 990px) {
  .service-container {
    .inner-container {
      width: 45%;
      .left-container {
        text-align: center;
        margin: auto;
      }
    }
    .inner-container:nth-child(even) {
      flex-direction: column;
    }
  }
}

@media (max-width: 600px) {
  .service-container {
    .inner-container {
      width: 90%;
      .left-container {
        text-align: center;
        margin: auto;
        .founder-image-container {
          height: 95%;
          width: 95%;
          overflow: hidden;
          // border: 1px solid red;
          display: flex;
          justify-content: center;
          align-items: center;

          .founder-image {
            height: 60%;
            width: 60%;
            object-fit: contain;
            margin: auto;
          }
        }
      }
    }
    .inner-container:nth-child(even) {
      flex-direction: column;
    }
  }
}
