.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  padding: 0 3rem;
}



.servicardMain {
  text-align: center;
}

.cardWrapper {
  height: 270px;
  width: 270px;
  background-size: cover;
  background-color: var(--light-shadow);
  background-blend-mode: multiply;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--bg-light);
  transition: 0.3s ease-in-out all;

  &:hover {
    cursor: pointer;
    background-color: var(--dark-accent);
    border-top-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
  }
}

.serviceLogo {
  font-size: 4rem;
}

.serviceName {
  margin-top: 7px;
  font-size: 1.1rem;
  font-weight: 400;
  text-align: center;
}

.servicardMain > button {
  width: 100%;
  background: var(--accent);
  border: 1px solid var(--border);
  padding: 5px 0;
  margin-top: 7px;
  border-radius: 15px;
  color: var(--text-secondary);
  font-size: 0.9rem;

  &:hover {
    color: var(--text-primary);
    background-color: var(--light-accent);
    border: 1px solid var(--text-primary);
  }
}

@media (max-width: 1020px) {
  .container {
    padding: 2rem;
    gap: 1rem;
  }

  .cardWrapper {
    height: 230px;
    width: 230px;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 0;
    margin: 0;
  }
}
@media (max-width: 580px) {
  .container {
    padding: 1rem;
  }
}

@media (max-width: 510px) {
  .cardWrapper {
    height: 250px;
    width: 250px;
  }
}
