.heading {
  height: 400px;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgb(134, 134, 134);
  background-blend-mode: none;
  display: flex;
  align-items: center;
  justify-content: center;
    
}

.headingText{
  font-size: 3rem;
  font-weight: 700;
  color: var(--text-secondary);
  z-index: 9;
}

.overlay {
  position: absolute;
  inset: 0;
  background: #131313a9;
}

.about_Service {
  height: 100%;
  width: 100%;
  display: flex;
  margin: 3rem 0;
  padding: 0 5rem;
  justify-content: space-between;
  align-items: center;
  /* align-items: flex-start; */
  /* background: lightblue; */
}

.about_text_content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  line-height: 1.5rem;
  width: 45%;
}

.video {
  width: 45%;
  border-radius: 5px;
}

.aboutDesc {
  font-size: 1rem;
}

.aboutus {
  width: 90%;
  margin: auto;
}

@media (max-width: 1024px) {
  .heading {
    height: 350px;
  }

  .about_Service{
    margin: 0;
  }

  .ourStoryWrapper {
    padding: 0rem 3rem 1rem 3rem !important;
    margin: 0 !important;
    gap: 1rem;
  }

  .about_text_content,
  .video {
    width: 100%;
  }

  video {
    height: 400px;
  }
}

@media (max-width: 768px) {
  .heading {
    height: 300px;
  }

  .headingText{
    font-size: 2.5rem;
  }

  .ourStoryWrapper {
    padding: 0rem 3rem 1rem 3rem !important;
    margin: 0 !important;
    gap: 1rem;
  }

  .ourStoryText {
    gap: 10px !important;
  }

  .ourStoryHead {
    font-size: 20px !important;
  }

  video {
    height: 20%;
  }
}

@media (max-width: 600px) {
  .heading {
    height: 250px;
    font-size: 2rem;
  }
}

@media (max-width: 450px) {
  .heading {
    height: 200px;
    
  }

  .headingText{
    font-size: 1.8rem;
  }

  .about_Service {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem;
  }

  .about_text_content {
    padding: 0;
  }

  .aboutDesc {
    font-size: 0.9rem;
    line-height: 1.2rem;
  }

  .aboutImg {
    width: 100%;
    object-fit: cover;
  }
}

.ourStoryWrapper {
  padding: 0 6rem;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  justify-content: center;
}

.ourStoryText {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ourStoryHead {
  font-size: 25px;
  font-weight: 600;
}

.ourStoryDesc {
  font-size: 15px;
  text-align: justify;
}

.ourStoryImg {
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
  max-height: 400px;
}

.ourStoryWrapperpart2 {
  /* background-color: #dbffe0; */
  padding: 100px 200px 100px 200px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: center;
  align-items: center;
}
.subHeading {
  color: #2a6231;
  text-decoration: underline;
}

@media (max-width: 450px) {
  .ourStoryWrapper {
    flex-direction: column;
    align-items: center;
    gap: 60px;
    padding: 1rem !important;
  }

  .ourStoryWrapperpart2 {
    flex-direction: column;
    align-items: center;
    gap: 60px;
    padding: 50px;
  }

  .ourStoryHead {
    /* text-align: center; */
  }

  .ourStoryImg {
    width: 20rem;
  }
}

/* media queries for tablets  */
@media (min-width: 450px) and (max-width: 1024px) {
  .about_Service,
  .ourStoryWrapper {
    flex-direction: column-reverse;
    padding: 2rem;
    gap: 0rem;
  }
  .ourStoryWrapper {
    flex-direction: column;
    padding: 4rem;
    gap: 2rem;
  }
  .aboutImg {
    padding: 2rem;
  }
}
