.about-us-container {
  margin: 1rem 3rem 5rem 3rem;

  .heading-section {
    margin-bottom: 2rem;

    .heading {
      font-size: 2rem;
      font-weight: 600;
      color: var(--text-primary);
      text-align: center;
    }
  }

  .founders-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 5px;

    .founder-container {
      width: 95%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 1rem;
      border: 2px solid var(--border);
      padding: 10px;
      border-radius: 10px;
      transition: transform 0.3s ease, box-shadow 0.3s ease;

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
      }
      @media (max-width: 350px) {
        flex-direction: column;
      }

      .left-container {
        .founder-image-container {
          height: 200px;
          width: 200px;

          .founder-image {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 5px;
          }
        }
      }

      .right-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: fit-content;
        padding: 10px;

        .primary-details {
          .name,
          .designation,
          .about,
          .headd {
            line-height: 1rem;
          }

          .name {
            font-size: 1.5rem;
            font-weight: 500;
            color: var(--text-primary);
          }

          .designation {
            font-size: 1rem;
            font-weight: 500;
            color: var(--text-dark-grey);
          }
        }

        .about {
          font-size: 0.9rem;
          color: var(--text-primary);
          line-height: 1.1rem;
        }
      }
    }

    .founder-container:nth-child(even) {
      flex-direction: row-reverse;
    }
  }

  .about-company-container {
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .company-image-container {
      width: 50%;
      display: flex;
      align-items: center;

      .image-container {
        height: fit-content;

        .company-image {
          height: 100%;
          width: 100%;
          object-fit: contain;
          border-radius: 10px;
        }
      }
    }

    .company-details {
      width: 45%;

      .company-primary-details {
        margin-bottom: 1rem;

        .subheading {
          font-size: 1.5rem;
          font-weight: 600;
          text-transform: uppercase;
          color: var(--text-primary);
          margin-bottom: 10px;
        }

        ul li p {
          font-size: 1rem;
          color: var(--text-primary);
        }
      }

      .para {
        font-size: 0.95rem;
        color: var(--text-primary);
      }

      p {
        margin-top: 0.5rem;
      }
    }
  }
  p {
    margin-top: 0.5rem;
  }
}

@media (max-width: 1100px) {
  .about-us-container {
    .founders-container {
      .founder-container {
        width: 100%;
      }
    }
  }
}

@media (max-width: 930px) {
  .about-us-container {
    .about-company-container {
      margin-top: 2rem;
      flex-direction: column;
      gap: 2rem;

      .company-image-container,
      .company-details {
        width: 100%;
      }

      .company-image-container {
        .image-container {
          height: fit-content;

          .company-image {
            object-fit: contain;
          }
        }
      }
    }
  }
}

@media (max-width: 850px) {
  .about-us-container {
    .founders-container {
      flex-wrap: wrap;
      .founder-container {
        flex-direction: column;
        &.founder-container:nth-child(even) {
          flex-direction: column;
        }
        .left-container {
          display: flex;
          text-align: center;
          margin: auto;

          .founder-image-container {
            height: 150px;
            width: 150px;
          }
        }

        .right-container {
          padding: 0px;

          .primary-details {
            text-align: center;
            .name {
              font-size: 1.3rem;
            }

            .designation {
              font-size: 0.9rem;
            }
          }

          .about {
            font-size: 0.8rem;
            line-height: 1rem;
          }
        }
      }
    }

    .about-company-container {
      .company-details {
        .company-primary-details {
          .subheading {
            font-size: 1.3rem;
          }

          ul li p {
            font-size: 0.9rem;
            line-height: 1rem;
          }
        }

        .para {
          font-size: 0.9rem;
          line-height: 1.2rem;
        }
      }
    }
  }
}

// @media (max-width: 768px) {
//   .about-us-container {
//     .founder-container {
//       flex-direction: column;
//     }
//   }
// }
@media (max-width: 600px) {
  .about-us-container {
    margin: 1rem 1rem 5rem 1rem;

    .founders-container {
      .founder-container,
      .founder-container:nth-child(even) {
        width: 100%;
        // flex-direction: column;

        .left-container {
          align-self: flex-start;

          .founder-image-container {
            height: 150px;
            width: 150px;
          }
        }

        .right-container {
          padding: 0px;

          .primary-details {
            .name {
              font-size: 1rem;
            }

            .designation {
              font-size: 0.9rem;
            }
          }

          .about {
            font-size: 0.8rem;
            line-height: 1rem;
          }
        }
      }
    }

    .about-company-container {
      gap: 1rem;

      .company-details {
        .company-primary-details {
          .subheading {
            font-size: 1.3rem;
          }

          ul li p {
            font-size: 0.85rem;
          }
        }

        .para {
          font-size: 0.85rem;
          line-height: 1.1rem;
        }
      }
    }
  }
}
