.sb_footer {
  background-color: black;
  display: flex;
  flex-direction: column;
  padding: 3rem;
}

.sb_footer_link {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  text-align: left;
  margin-bottom: 2rem;
}

.sub_footer_div_link {
  width: 200px;
  /* margin: 1rem; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: rgb(177, 177, 177);
  /* background: lightcyan; */
}

.sub_footer_div_link a,
.socialmedia a,
.sb_footer_below_links {
  color: rgb(206, 206, 206);
  text-decoration: none;
}

.socialmedia {
  padding-top: 1rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.sub_footer_div_link h4 {
  color: var(--text-secondary);
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 0.9rem;
}

.sub_footer_div_link p,
.sub_footer_div_link .footer_sub_add .social {
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 10px;
}

.social {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.social a {
  display: flex;
  gap: 10px;
  align-items: center;
}
.social p {
  margin: 0;
}

.footerImg {
  margin-top: 2rem;
  text-align: start;
}

.hr {
  color: white !important;
  width: 100%;
}

.sb__footer_below {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.sb_footer_below_links {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sb_footer_below_links p {
  font-size: 12px;
  margin-left: 2rem;
  color: rgb(255, 255, 255);
}

.sb__footer_copyright p {
  font-size: 15px;
  color: rgb(255, 255, 255);
  margin-top: 5px;
  font-weight: 300;
}

@media (max-width: 850px) {
  .sb_footer {
    padding: 2rem;
  }
}

@media (max-width: 760px) {
  .sb_footer_link {
    justify-content: flex-start;
    gap: 1.5rem;
  }

  .footerImg {
    margin-top: 3rem;
  }
}

@media (max-width: 550px) {
  .sb_footer_link {
    justify-content: flex-start;
  }

  .footerImg {
    margin-top: 0rem;
  }

  .sb__footer_below {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .sb__footer_copyright p {
    text-align: center;
  }

  .sb_footer_below_links {
    flex-direction: column;
    margin-top: 5px;
  }

  .sb_footer_below_links p {
    margin-left: 0rem;
    margin-top: 0.5rem;
  }
}
