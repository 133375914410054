.Main-con {
  position: relative;
  z-index: 1;
}
.whatsapp {
  display: inline;
  position: fixed;
  right: 4%;
  bottom: 7%;
  text-align: end;
  z-index: 10;
}
.whatsapp img {
  width: 50px;
  height: 50px;
}
