.FAQs_main {
  height: 100%;
  padding: 1rem 3rem;
  margin: auto;

  .heading {
    font-size: 2rem;
    font-weight: 600;
    color: var(--text-primary);
  }

  .faq-row {
    margin-top: 3rem;

    .faq-col {
      .faq-content {
        p {
          display: none;
        }
      }
    }

    .faq-col {
      cursor: pointer;
    }

    .faq-col {
      cursor: pointer;
      p {
        display: none;
      }
    }

    .faq-content.show {
      border: 1px solid var(--light-shadow);
      border-radius: 10px;
      padding: 1rem;
      background: var(--bg-grey);
      transition: all 0.4s ease;
      width: 55%;

      h4 {
        font-weight: 600;
      }

      p {
        display: block;
        margin-top: 7px;
        font-size: 0.9rem;
        line-height: 1.1rem;
      }
    }

    .faq-content.show-light {
      border: 1px solid #7f827f;
      border-radius: 10px;
      padding: 1rem;
      background: rgba(128, 128, 128, 0.217);
      transition: all 0.4s ease;
      width: 90%;
      h5 {
        background: transparent;
      }
      p {
        display: block;
        background: transparent;
      }
    }

    .bi {
      transition: transform 0.3s ease;
    }

    .rotate-icon {
      transform: rotate(180deg);
    }
  }

  .first-div {
    display: flex;
    padding: auto 3rem;
    flex-direction: column;
    justify-content: center;

    .title {
      font-size: 1.1rem;
      font-weight: 500;
      color: var(--text-primary);
    }

    .description {
      font-size: 0.9rem;
    }
  }
}

@media (max-width: 1000px) {
  .FAQs_main {
    .faq-row {
      margin-top: 1.5rem;

      .faq-content.show {
        width: 70%;
      }
    }

    .first-div {
      width: 95%;
    }
  }
}

@media (max-width: 767px) {
  .FAQs_main {
    padding: 1rem;

    .faq-row {
      margin-top: 1.5rem;

      .faq-content.show {
        width: 100%;
      }
    }

    .first-div {
      width: 95%;
    }
  }
}

@media (max-width: 550px) {
  .faq-row {
    .first-div {
      display: flex;
      flex-direction: column;
      align-items: left;

      .faq-col {
        gap: 10px !important;
        margin-bottom: 5px !important;

        .faq-content.show {
          width: 100%;

          .title {
            font-size: 1rem;
          }

          .description {
            font-size: 0.85rem;
          }
        }
      }
    }
  }
}
