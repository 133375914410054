.servicesHead {
  margin-top: 3rem;
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
}

.faqContainer {
  margin: 5rem auto;
}

.contact_form {
  background-color: #dbffe0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 90%;
  border-radius: 10px;
}

.formHeader {
  font-size: 20px;
  font-weight: 600;
}

.label {
  font-weight: 500;
}

.formInput {
  outline: none;
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  font-size: 16px;
}

.submitBtn {
  background-color: var(--accent);
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.1s ease-in-out all;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  font-size: 14px;

  &:hover {
    background-color: #25542b;
  }
}

.input_group > div {
  flex: 1;
}

.input_group {
  display: flex;
  gap: 1rem;
}

/* ABOUT SERVICES */
.aboutServices {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3.5rem;
}

.servicesLeft {
  width: 55%;
  /* display: flex; */
  flex-direction: column;
  gap: 2rem;
}

.leftHead {
  font-size: 1.8rem;
  line-height: 5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.leftDesc {
  font-size: 0.95rem;
  /* color: var(--text-dark-grey); */
}

.serviceImageContainer {
  width: 40%;
  height: 100%;
}

.aboutServicesImg {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 7px;
}

.singleBusinessContainer {
  position: fixed;
  inset: 0;
  background: var(--text-dark-grey);
  /* height: 100%; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  z-index: 99;
}

.mainContainer {
  margin-top: 2rem;
  /* margin-bottom: 2rem; */
  background: var(--bg-light);
  width: 70%;
  /* height: 105%; */
  border-radius: 10px;
  /* padding-bottom: 2rem; */
  /* overflow: scroll; */
}
.mainContainer::-webkit-scrollbar {
  display: none;
}

.topContainer {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.imageContainer {
  width: 100%;
  height: 150px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: relative;
  text-align: end;
  padding-right: 2rem;
  @media (max-width: 480px) {
    padding-right: 0;
    text-align: center;
  }
}

.businessImage {
  height: 100%;
  /* width: 100%; */
  object-fit: cover;
  opacity: 0.8;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.overlay {
  position: absolute;
  inset: 0;
  background: #13131376;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.titleContainer {
  position: absolute;
  top: 10%;
  left: 2%;
}

.businessName {
  font-weight: 600;
  color: var(--text-secondary);
}

.closeIcon {
  cursor: pointer;
  position: absolute;
  top: 5%;
  right: 1%;
}

.bottomContainer {
  padding: 2rem;
  height: 100%;
  width: 100%;
  /* border: 11px solid red; */
}

.aboutSection {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.about {
  font-size: 1rem;
  color: var(--text-grey);
}

@media (max-width: 1100px) {
  .aboutServices {
    gap: 3rem;
  }

  .serviceImageContainer {
    width: 100%;
  }

  .servicesLeft {
    width: 100%;
    gap: 1rem;
  }
}

/* @media (max-width:900px) {
  .singleBusinessContainer{
    padding-top: 32rem;
  }
  
} */

@media (max-width: 720px) {
  .aboutServices {
    gap: 2rem;
    padding: 2rem;
    margin-top: -2rem;
  }

  .serviceImageContainer {
    width: 100%;
    height: 300px;
  }

  .faqContainer {
    margin: 2rem auto;
  }
}

@media (max-width: 550px) {
  .leftHead {
    font-size: 1.5rem;
    line-height: 3rem;
  }

  .leftDesc {
    font-size: 0.85rem;
  }
  /* .singleBusinessContainer{
    padding-top: 37rem;
  } */
}

@media (max-width: 450px) {
  .aboutServices {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem;
  }

  .servicesLeft {
    align-items: center;
  }

  .leftHead {
    font-size: 1.3rem;
    line-height: 2.5rem;
    text-align: center;
  }

  .leftDesc {
    line-height: 1.1rem;
  }

  .contactFormAndAddress {
    flex-direction: column;
    padding: 30px;
    gap: 50px;
  }

  .locationHeader {
    text-align: center;
    padding: 5px;
  }

  .contact_locations {
    width: 100%;
  }

  .contact_form {
    width: 100%;
  }

  .submitBtn {
    width: 100%;
    padding: 10px 0px;
  }

  .mainContainer {
    width: 85%;
  }
}

/* media queries for tablets  */

@media screen and (min-width: 450px) and (max-width: 1100px) {
  .aboutServices {
    flex-direction: column-reverse;
  }
}

@media (max-width: 768px) {
  .servicesHead {
    margin: 0;
  }
}
