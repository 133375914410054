.testimonialWrapper {
  margin: 3rem auto;
}

.heading {
  font-size: 2rem;
  font-weight: 600;
  color: var(--text-primary);
  text-align: center;
  margin-bottom: 1.5rem;
}

.paratext{
  line-height:.5rem;
  color: #212529ea;
}

