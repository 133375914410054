.contact_us {
  padding-bottom: 50px;
}

.heading {
  font-size: 2rem;
  font-weight: 600;
  color: var(--text-primary);
}

.contactFormAndAddress {
  display: flex;
  padding: 3rem;
  gap: 2rem;
  justify-content: space-between;
}

.locationHeader {
  font-size: 24px;
  font-weight: 600;
}

.contact_locations {
  background: var(--bg-light);
  padding: 3rem;
  width: 50%;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  gap: 1rem;
}

.subheading {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--text-primary);
}

.contactContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
}

.contactContainer h6,
.socialLinks h6 {
  margin-bottom: 3px;
}

.socialLinks > div {
  display: flex;
  gap: 1rem;
}

.locationContainer {
  margin-top: 1rem;
}

.locationContainer h4 {
  margin-bottom: 10px;
}

.contact_form {
  background: var(--bg-light);
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
  padding: 3rem;
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.label {
  font-weight: 500;
  margin-bottom: 5px;
}

.linkcolor {
  color: #303030;
  display: flex;
  gap: 10px;
}

.formInput {
  outline: none;
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid gray;
}

.submitBtn {
  background-color: #303030;
  color: white;
  padding: 10px 3rem;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  transition: 0.1s ease-in-out all;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin: auto;

  &:hover {
    background-color: #252525;
  }
}

@media (max-width: 1100px) {
  .contactFormAndAddress {
    padding: 2rem;
  }

  .contact_locations,
  .contact_form {
    padding: 2rem;
  }

  .contactContainer {
    flex-direction: column;
    gap: 10px;
  }
}

@media screen and (max-width: 768px) {
  .contactFormAndAddress {
    flex-direction: column-reverse;
    padding: 30px;
    gap: 50px;
  }

  .locationHeader {
    text-align: center;
    padding: 5px;
  }

  .contact_locations {
    width: 100%;
  }

  .contact_form {
    width: 100%;
  }

  .submitBtn {
    width: 100%;
    padding: 10px 0px;
  }
  .map {
    width: 100% !important;
    height: 100% !important;
    margin: auto;
  }
}

@media screen and (max-width: 450px) {
  .contact_locations,
  .contact_form {
    padding: 1.5rem;
  }

  .contactFormAndAddress {
    gap: 30px;
  }

  .contact_info {
    gap: 25px;
    padding: 30px;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    justify-content: center;
  }

  .info_section {
    height: fit-content;
    width: fit-content;
  }

  .contact_form {
    gap: 13px;
  }

  .locationContainer p {
    line-height: 1.3rem;
  }
}

/* media queries for tablets  */
@media screen and (min-width: 450px) and (max-width: 1024px) {
  .contact_info {
    grid-template-columns: repeat(2, 1fr);
  }
}
