@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap");

.slider_carousel {
  width: 90%;
  margin: auto;
}

.slider_item {
  /* margin: 1rem; */
  overflow: hidden;
  height: fit-content;
}

figure.main-container {
  display: flex;
  justify-content: space-between;
  font-family: "Raleway", Arial, sans-serif;
  position: relative;
  overflow: hidden;
  border: 1px solid rgb(187, 187, 187);
  overflow: hidden;
  height: 200px;
  width: 95%;
  border-radius: 7px;
  color: #333;
  text-align: left;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  background: var(--bg-light);
}

figure.main-container * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

figure.main-container img {
  float: right;
  max-width: 30%;

  object-fit: cover;
  vertical-align: middle;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

figure.main-container blockquote {
  position: relative;
  padding: 20px;
  font-size: 0.9rem;
  text-align: left;
  line-height: 1.1rem;
  font-style: italic;
  text-transform: none;
  color: var(--text-dark-grey);
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 7; /* Show only 3 lines on small screens */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

figure.main-container blockquote:before,
figure.main-container blockquote:after {
  font-family: "FontAwesome";
  content: "\201C";
  position: absolute;
  font-size: 30px;
  opacity: 0.3;
  font-style: normal;
}

figure.main-container blockquote:before {
  top: 16px;
  left: 5px;
}

figure.main-container blockquote:after {
  content: "\201D";
}

figure.main-container .arrow {
  position: absolute;
  top: 30px;
  left: 100%;
  width: 0;
  height: 0;
  border-left: 0 solid transparent;
  border-right: 25px solid transparent;
  border-top: 25px solid #ffffff;
  margin: 0;
}
figure.main-container .author {
  width: 80%;
  padding: 12px;
  margin: 0;
}
figure.main-container .author h5 {
  color: var(--text-primary);
  font-weight: 600;
  font-size: 1rem;
  margin: 0;
  text-transform: capitalize;
}

@media (max-width: 680px) {
  figure.main-container {
    justify-content: space-between;
    margin: auto;
    padding: 0;
  }

  figure.main-container img {
    float: right;
    width: 30%;

    object-fit: cover;
  }
}

@media (max-width: 480px) {
  figure.main-container {
    width: 100%;
    gap: 0;
    padding: 0;
    margin: 0;
  }
  .author {
    width: 60%;
    padding: 10px;
  }
  figure.main-container img {
    float: none;
    width: 40%;
    object-fit: cover;
  }
}
