.navbar {
  background-color: rgb(255, 255, 255);
  position: relative;
}

.container {
  /* max-width: 1100px; */

  margin: 0 auto;
  width: 100%;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.logoContainer {
  height: 80px;
  width: 200px;
}
.combo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.linkcolor {
  padding-left: 0.5rem;
  display: flex;
  gap: 10px;
  color: black;
}
.logo {
  height: 100%;
  width: 100%;
  object-fit: cover;
  cursor: pointer;
}

.menu_icon {
  display: none;
}

.closeIcon {
  display: none;
}

.call {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  text-align: center;
  border: 1px solid var(--accent);
  border-radius: 5px;
  color: var(--accent);
  background-color: var(--light-accent);
  transition: 0.3s ease-in-out all;
  padding: 0.5rem 2rem;

  &:hover {
    cursor: pointer;
    color: var(--text-secondary);
    background-color: var(--accent);
  }
}

.qoute {
  text-align: center;
  border: 1px solid var(--accent);
  border-radius: 5px;
  color: var(--accent);
  background-color: var(--light-accent);
  transition: 0.3s ease-in-out all;
  padding: 0.5rem 2rem;

  &:hover {
    cursor: pointer;
    color: var(--text-secondary);
    background-color: var(--accent);
  }
}

.nambarr {
  display: flex;
}
.tablist {
  margin: 0;
  display: flex;
}

.tab {
  font-weight: 500;
  cursor: pointer;
}

.btnContainer {
  display: none;
}

@media (max-width: 800px) {
  .nambarr {
    display: none;
  }
  .btnContainer {
    display: contents;
    margin-top: 5rem;
    display: flex;
    gap: 1rem;
    padding: 1rem;
  }
}
@media (max-width: 768px) {
  .call {
    margin-left: 0px;
  }
}

@media (max-width: 800px) {
  .container {
    padding: 0 2rem;
  }

  .menu_icon {
    display: block;
    cursor: pointer;
  }

  .nav_elements {
    position: fixed;
    right: -270px;
    top: 0;
    background: var(--text-light-grey);
    z-index: 100;
    height: 100vh;
    width: 270px;
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav_elements.active {
    right: 0;
  }

  .closeIcon {
    position: absolute;
    top: 10px;
    right: 43px;
    background: var(--bg-primary);
    padding: 10px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sidebarOverlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: rgba(1, 1, 1, 0.5);
  }
}

@media (max-width: 600px) {
  .container {
    padding: 0 1rem;
  }

  .logoContainer {
    height: 60px;
    width: 150px;
  }
  .logo {
    /* object-fit:cover; */
  }
}

/* media queries for tablets  */
@media screen and (min-width: 600px) and (max-width: 900px) {
  .nav_elements ul {
    gap: 1rem;
  }
}

@media (max-width: 370px) {
  .container {
    padding: 0;
  }
}
