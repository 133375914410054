.Currentwork {
  .innerDiv {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
    .cardss {
      width: 26%;
      height: 100%;
      padding: 20px;
      border-radius: 5px;
      border: 1px solid rgba(128, 128, 128, 0.582);
      .workimg {
        height: 40%;
        width: 100%;
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
          cursor: pointer;
          //  &:hover{
          //      transform: scale(1.05);
          //  }
        }
      }
      p {
        font-size: 14px;
        b {
          font-weight: 500;
        }
      }
    }
  }
}

@media (max-width: 890px) {
  .Currentwork {
    .innerDiv {
      .cardss {
        width: 48%;
      }
    }
  }
}
@media (max-width: 600px) {
  .Currentwork {
    .innerDiv {
      .cardss {
        width: 93%;
      }
    }
  }
}
