// Teammember.scss

.mainmebdiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0;

  .members {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 250px;
    margin: 5px;
    padding: 10px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }

    .imgs {
      width: 100%;
      height: 230px;
      border-radius: 10px;
    }

    .infodetails {
      margin-top: 15px;

      h3 {
        font-size: 1.2em;
        color: #333;
        margin: 0;
      }

      p {
        font-size: 1em;
        color: #666;
        margin: 0;

      }
    }
  }
}
