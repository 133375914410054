body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

h1 {
  text-align: center;
  margin: 20px 0;
}

.blog-container {
  width: 90%;
  max-width: 1250px;
  margin: 0 auto;

  .blog-section {
    display: flex;
    align-items: center;
    margin: 20px 0;

    &.left-right {
      flex-direction: row; // Default layout: Image on the left, Content on the right
    }

    &.right-left {
      flex-direction: row-reverse; // Reversed layout for even divs: Image on the right, Content on the left
    }

    .blog-image {
      flex: 1;
      padding: 10px;

      img {
        max-width: 100%;
        height: auto;
        border-radius: 10px;
      }
    }

    .blog-content {
      flex: 1;
      padding: 10px;

      h2 {
        margin-top: 0;
      }

      p {
        margin: 10px 0;
      }
    }
  }
}

@media (max-width: "768px") {
  .blog-container {
    .blog-section {
      margin: 0;
      &.left-right {
        flex-direction: column; // Default layout: Image on the left, Content on the right
      }

      &.right-left {
        flex-direction: column; // Reversed layout for even divs: Image on the right, Content on the left
      }

      .blog-image {
        padding: 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          max-width: 70%;
          height: 70%;
          object-fit: cover;
          margin: auto;
          border: 1px solid red;
        }
      }

      .blog-content {
        flex: 1;
        padding: 10px;
        text-align: justify;

        h2 {
          margin-top: 0;
          font-size: 1.4rem;
        }

        p {
          margin: 10px 0;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}
